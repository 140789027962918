  <template>
  <CustomTable style="min-width:100%; max-width:100px;"
    :tableName="tableName"
    :showStatus="false"
    :list="list"
    :pageProp="page"
    :pages="pages"
    :filterStatus="filterStatus"
    :showDownloadBtn="true"
    @paginatorHandler="paginatorHandler"
    @updateStatus="updateStatus"
    @queryForOccurences="queryForOccurences"
  ></CustomTable>
  
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import CustomTable from "@/components/customTables/CustomTableInvoices";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import store from "@/store";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Faturas",
  components: {
    CustomTable,

  },
  data() {
    return {
      tableName: "Detalhe",
      page: 1,
      pages: 0,
      list: [],
      itemsPerPage: 15,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
      filterStatus: ["Factura", "Fatura-Recibo", "Nota de Crédito", "Nota de Crédito P", "Nota de Débito"],


    };
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    queryForOccurences(searchInput) {
      this.searchInput = searchInput.searchInput;
      this.startDate = searchInput.startDate;
      this.endDate = searchInput.endDate;

      this.paginatorHandler(1);
    },



    /*
    * ------------------DATA MANIPULATION------------------
     */
    paginatorHandler(page) {
      this.page = page;
      this.loadInvoices(page)
    },
    //filters by status, update pages, and list to show
    updateStatus(status) {
      this.status = status;
      this.paginatorHandler(1);
    },



    /*
    * ------------------API FETCHING------------------
     */
    loadInvoices(page) {
      let queryString = this.getQueryString(page);

      ApiService.get("cliente/faturas", queryString)
      .then((response) => {
        this.pages = response.data.totalPages;
        this.list = response.data.data;
      });

    },

    /*
    * --------------------UTILS--------------------
     */
    getQueryString(page) {
      let queryString = `?pageNumber=${page}&pageSize=${this.itemsPerPage}&numCliente=${store.getters.currentUser.numcl}&NumSerie=1`;

      if(this.startDate) {
        queryString += `&startDate=${this.startDate}`
      }
      if(this.endDate) {
        queryString += `&endDate=${this.endDate}`
      }
      if(this.status) {
        queryString += `&nomeDocumento=${this.status}`
      }
      if(this.searchInput) {
        queryString += `&numFatura=${this.searchInput}`
      }

      return queryString;

    },

  },
  mounted() {
    setCurrentPageBreadcrumbs("Faturação");
    //only load from last year at first
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toISOString().substring(0,10);
    this.loadInvoices(1);
  },
});
</script>


