
import {defineComponent} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import {boolean} from "yup/lib/locale";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableInvoices",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    Dropdown2,
    CustomTableTopFilter,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    pages: Number,
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showPendingQtt: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences"],
  methods: {

    downloadpdf() {
    },

    showRow(rowId) {
      return this.rowsToShow.includes(rowId);
    },

    /*
    * ----------------DATA MANIPULATION----------------
    */

    toggleShowRowDetail(rowId) {
      const index = this.rowsToShow?.indexOf(rowId);
      if (index > -1) {
        this.rowsToShow.splice(index, 1);
        return;
      } else {
        this.rowsToShow.push(rowId);
        return;
      }
    },

    /*
    * ------------------EMITS------------------
    */

    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },

    /*
    * -------------------UTILS--------------------
    */
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    /*
    * -------------------STYLES-------------------
     */

    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Curso" ? "success" : "primary";
      return str + color;
    },
    rowColor(numFatura) {
      const index = this.list!.findIndex(
          (item: any) => item.numFatura == numFatura
      );
      if (index! % 2 == 0) {
        return "background-color: #fbfbfb;";
      } else {
        return "background-color: #efefef;";
      }
    },

    orderStatusColors(status) {
      switch (status.toUpperCase()) {
        case "CONCLUÍDA":
          return "background-color: #d9efcb; color: #3d9a01 !important; min-width:150px;";
        case "EM PROCESSAMENTO":
          return "background-color: #c6e3ec; color: #1ab3d4 !important; min-width:150px;";
        default:
          return "background-color: #e4ebf0; color: #181c32 !important; min-width:150px;";
      }
    },

  },

});
